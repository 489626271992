import * as React from "react"
import "../../static/style.css";
const IndexPage = () => {
  return (
    <main>
      <div className="wrapper">
        <div className="bg"> RAWR </div>
        <div className="fg"> RAWR </div>
      </div>
    </main>
  )
}

export default IndexPage